<template>
  <el-dialog title="学生信息编辑" class="info-div600" style="padding: 24px;text-align: left">
    <div>
      <el-form-item label="用户名" label-width="80px">
        <el-input v-model="Info.username" autocomplete="off" :disabled="Info.id?true:false" placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="密码" label-width="80px" v-if="!Info.id">
        <el-input v-model="Info.password" autocomplete="off" type="password"
                  placeholder="密码需8-18位包含数字大小写字母字符串"/>
      </el-form-item>
      <el-form-item label="学号" label-width="80px">
        <el-input v-model="Info.code" autocomplete="off" :disabled="Info.id?true:false" placeholder="唯一标识"
                  maxlength="20"/>
      </el-form-item>
      <el-form-item label="姓名" label-width="80px">
        <el-input v-model="Info.name" autocomplete="off" placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="院校" label-width="80px">
        <el-input v-model="Info.school" autocomplete="off" placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="专业" label-width="80px">
        <el-input v-model="Info.major" autocomplete="off" placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="班级" label-width="80px">
        <el-input v-model="Info.clasz" autocomplete="off" placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="手机号" label-width="80px">
        <el-input v-model="Info.phone" autocomplete="off" placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="邮箱" label-width="80px">
        <el-input v-model="Info.email" autocomplete="off" placeholder="填写邮箱"/>
      </el-form-item>
      <el-form-item label="限制预约" label-width="80px">
        <div style="display: flex;justify-content: space-between;width: 100%;">
          <el-date-picker v-model="Info.disableEndDate"
                          type="datetime"
                          placeholder="从限制预约至此日期内不能预约"
                          value-format="YYYY-MM-DD HH:mm:ss"
                          style="width: 100%;"/>
          <el-button type="primary" @click="queryHistory"
                     style="margin-left: 10px;--el-button-border-color: #3769B9;--el-button-bg-color: #3769B9;--el-button-hover-border-color: #3769B9;--el-button-hover-bg-color: #3769B9;">
            历史限制
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="操作缘由" label-width="80px">
        <el-input v-model="Info.disableMsg" type="textarea" placeholder="操作缘由"/>
      </el-form-item>
      <div class="dialog-footer marginTop30px text-right">
        <button class="subBtn" @click="subData">提交</button>
        <button class="qxBtn" @click="closeDiv">取消</button>
      </div>
    </div>
    <el-dialog v-model="editExamPopup" title="历史限制" class=""
               destroy-on-close append-to-body style="padding: 24px;text-align: left;top: 40px;width: 400px;">
      <div>
        <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
          <div style="margin: auto">
            keli
          </div>
        </div>
        <div class="dialog-footer marginTop30px text-right">
          <button class="qxBtn" @click="editExamPopup = false">关闭</button>
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import {addStudent, disableMsgAllById} from "@/api/api";

export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {id: '', name: '', code: '', username: ''},
      content: [''],
      editExamPopup: false,
    }
  },
  mounted() {
  },
  methods: {
    queryHistory() {
      if (this.Info) {
        disableMsgAllById(this.Info.id).then(res => {
          if (res.success) {
            console.log(res, '--------res--')
            this.editExamPopup = true
          }
        })
      }
    },
    closeDiv() {
      this.$emit('closeDiv');
    },
    getInfo(row) {//清空表单
      if (row) {
        this.Info = JSON.parse(row);
      } else {
        this.Info = {};
      }
    },
    changeDic(index, type) {
      if (type == 'add') {
        this.content.push('');
      } else if (type == 'reced') {
        this.content.splice(index, 1);
      }
    },
    subData() {//提交
      if ((!this.Info.username) || (!this.Info.code)) {
        this.$root.ElMessage.error('学号和用户名不能为空！');
        return false;
      }
      let method = 'post';
      if (this.Info.id) {
        method = 'put';
      }
      addStudent(this.Info, method).then((res) => {
        if (res.success) {
          this.$root.ElMessage({type: 'success', message: res.message});
          this.closeDiv();
          this.$emit('getList');
        } else {
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    handleAvatarSuccess(e) {//图片上传成功后
      this.Info.businessLicenseImg = e.data;
    }
  }
}
</script>

<style lang="scss" scoped>
.child_Dic {
  .icon_dic_css {
    font-size: 20px;
    margin-left: 10px;
    color: #cccccc
  }
}
</style>